<template>
  <el-row :gutter="12" v-if="show">
      <el-col :xs="24" :sm="12" :md="8">
         <cpu-echart :times="times" :data="cupData"></cpu-echart>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8">
         <mem-echart :data="memData"></mem-echart>
      </el-col>
       <el-col :xs="24" :sm="12" :md="8">
         <load-echart :times="times" :data="loadData" title="系统平均负载"></load-echart>
      </el-col>
    </el-row>
</template>

<script>
import CpuEchart from './CpuEchart.vue'
import MemEchart from './MemEchart.vue'
import LoadEchart from './LoadEchart.vue'
export default {
  name: 'EchartGroup',
  props: {
    times: {
      type: Object,
      default: () => {}
    },
    params: {
      type: Object,
      default: () => {}
    },
    random: {
      type: [Number, String],
      default: '0'
    }
  },
  components: { CpuEchart, MemEchart, LoadEchart },
  data() {
    return {
      show: false,
      cupData: [
        {
          name: 'x轴',
          data: []
        },
        {
          name: '总使用率',
          data: []
        },
        {
          name: '系统使用率',
          data: []
        },
        {
          name: '用户使用率',
          data: []
        },
        {
          name: '磁盘IO使用率',
          data: []
        }
      ],
      memData: [
        {
          name: 'x轴',
          data: []
        },
        {
          name: '总内存',
          data: []
        },
        {
          name: '已用',
          data: []
        },
        {
          name: '可用',
          data: []
        },
        {
          name: '使用率',
          data: []
        }
      ],
      loadData: [
        {
          name: 'x轴',
          data: []
        },
        {
          name: 'CPU总核数',
          data: []
        },
        {
          name: '1分钟负载',
          data: []
        },
        {
          name: '5分钟负载',
          data: []
        },
        {
          name: '15分钟负载',
          data: []
        }
      ]
    }
  },
  watch: {
    'params.instance': {
      handler: function(val) {
        if (val) {
          this.show = true
          this.getCpuData()
          this.getMemData()
          this.getLoadData()
        } else {
          this.show = false
        }
      },
      immediate: true
    },
    random(val) {
      if (!this.params.instance) return
      this.getCpuData()
      this.getMemData()
      this.getLoadData()
    }
  },
  methods: {
    async getCpuData() {
      const res = await Promise.all([
        // 总使用率
        this.$api.server.queryDataTotal({
          query: `(1 - avg(rate(node_cpu_seconds_total{instance=~'${this.params.instance}',mode='idle'}[${this.params.interval}])) by (instance))*100`,
          ...this.times
        }),
        // 系统使用率
        this.$api.server.queryDataTotal({
          query: `avg(rate(node_cpu_seconds_total{instance=~'${this.params.instance}',mode='system'}[${this.params.interval}])) by (instance) *100`,
          ...this.times
        }),
        // 用户使用率
        this.$api.server.queryDataTotal({
          query: `avg(rate(node_cpu_seconds_total{instance=~'${this.params.instance}',mode='user'}[${this.params.interval}])) by (instance) *100`,
          ...this.times
        }),
        // 磁盘IO使用率
        this.$api.server.queryDataTotal({
          query: `avg(rate(node_cpu_seconds_total{instance=~'${this.params.instance}',mode='iowait'}[${this.params.interval}])) by (instance) *100`,
          ...this.times
        })
      ])
      this.toEmpty(this.cupData)
      console.log(res)
      res.forEach((item, index) => {
        Object.keys(JSON.parse(item.data).data.result[0].values).forEach(it => {
          if (index === 0) {
            this.cupData[0].data.push(JSON.parse(item.data).data.result[0].values[it][0].toString())
          }
          this.cupData[index + 1].data.push(Number(JSON.parse(item.data).data.result[0].values[it][1]))
        })
      })
    },
    async getMemData() {
      const res = await Promise.all([
        // 总内存
        this.$api.server.queryDataTotal({
          query: `node_memory_MemTotal_bytes{instance=~'${this.params.instance}'}`,
          ...this.times
        }),
        // 已用
        this.$api.server.queryDataTotal({
          query: `node_memory_MemTotal_bytes{instance=~'${this.params.instance}'} - node_memory_MemAvailable_bytes{instance=~'${this.params.instance}'}`,
          ...this.times
        }),
        // 可用
        this.$api.server.queryDataTotal({
          query: `node_memory_MemAvailable_bytes{instance=~'${this.params.instance}'}`,
          ...this.times
        }),
        this.$api.server.queryDataTotal({
          query: `(1 - (node_memory_MemAvailable_bytes{instance=~'${this.params.instance}'} / (node_memory_MemTotal_bytes{instance=~'${this.params.instance}'})))* 100`,
          ...this.times
        })
      ])
      this.toEmpty(this.memData)
      res.forEach((item, index) => {
        Object.keys(JSON.parse(item.data).data.result[0].values).forEach(it => {
          if (index === 0) {
            this.memData[0].data.push(JSON.parse(item.data).data.result[0].values[it][0].toString())
          }
          if (index !== 3) {
            this.memData[index + 1].data.push(Number(JSON.parse(item.data).data.result[0].values[it][1]) / (Math.pow(1024, 3)))
          } else {
            this.memData[index + 1].data.push(Number(JSON.parse(item.data).data.result[0].values[it][1]))
          }
        })
      })
    },
    async getLoadData() {
      const res = await Promise.all([
        // CPU总核数
        this.$api.server.queryDataTotal({
          query: `sum(count(node_cpu_seconds_total{instance=~'${this.params.instance}', mode='system'}) by (cpu,instance)) by(instance)`,
          ...this.times
        }),
        // 1分钟负载
        this.$api.server.queryDataTotal({
          query: `node_load1{instance=~'${this.params.instance}'}`,
          ...this.times
        }),
        // 5分钟负载
        this.$api.server.queryDataTotal({
          query: `node_load5{instance=~'${this.params.instance}'}`,
          ...this.times
        }),
        // 15分钟负载
        this.$api.server.queryDataTotal({
          query: `node_load15{instance=~'${this.params.instance}'}`,
          ...this.times
        })
      ])
      this.toEmpty(this.loadData)
      res.forEach((item, index) => {
        Object.keys(JSON.parse(item.data).data.result[0].values).forEach(it => {
          if (index === 0) {
            this.loadData[0].data.push(JSON.parse(item.data).data.result[0].values[it][0].toString())
          }
          this.loadData[index + 1].data.push(Number(JSON.parse(item.data).data.result[0].values[it][1]))
        })
      })
    },
    toEmpty(arr) {
      arr.forEach(item => {
        item.data = []
      })
    }
  }
}
</script>

<style>

</style>
