<template>
  <div class="ym-page" ref="server">
    <search-form :data="form" :params="paramStr" @change="change"></search-form>
    <table-group :list="list" @on-details="onDetails"></table-group>
    <total-echarts :form="form" :paramStr="paramStr" v-if="flag" :times="times" :random="key"></total-echarts>
    <div ref="instance">
      <template v-if="show">
        <div class="label">资源明细({{params.instance}})</div>
        <echart-group :times="times" :params="params" :random="key"></echart-group>
      </template>
    </div>
  </div>
</template>

<script>
import { deepClone } from '@/utils/deepClone'
import SearchForm from './components/Form'
import TableGroup from './components/TableGroup.vue'
import EchartGroup from './components/EchartGroup.vue'
import TotalEcharts from './components/TotalEcharts.vue'
export default {
  components: {
    SearchForm,
    TableGroup,
    EchartGroup,
    TotalEcharts
  },
  data() {
    return {
      form: {
        app: '',
        instance: '',
        ip: '',
        job: '',
        nodename: '',
        interval: '30s'
      },
      list: [],
      paramStr: '',
      result: [],
      defaultTime: '1m',
      timer: null,
      cupTotal: [
        {
          label: 'x轴',
          data: []
        },
        {
          label: '总核数',
          data: []
        },
        {
          label: '总5分钟负载',
          data: []
        },
        {
          label: '平均使用率',
          data: []
        }
      ],
      memTotal: [
        {
          label: 'x轴',
          data: []
        },
        {
          label: '总内存/GiB',
          data: []
        },
        {
          label: '总已用/GiB',
          data: []
        },
        {
          label: '总平均使用率',
          data: []
        }
      ],
      filesystemTotal: [
        {
          label: 'x轴',
          data: []
        },
        {
          label: '总磁盘量/GiB',
          data: []
        },
        {
          label: '总使用量/GiB',
          data: []
        },
        {
          label: '总平均使用率',
          data: []
        }
      ],
      times: {
        start: new Date().getTime() / 1000 - 12 * 60 * 60,
        end: new Date().getTime() / 1000,
        step: 1800
      },
      show: false,
      params: {
        instance: ''
      },
      flag: false,
      key: 0
    }
  },
  created() {
    this.init()
    this.timer = setInterval(() => {
      this.initTime()
      this.getData()
      this.key = Math.random()
    }, 20000)
  },
  methods: {
    change(data) {
      this.form = data
      this.show = false
      this.init()
    },
    // 查看明细
    onDetails(instance) {
      this.params.instance = instance
      this.params.interval = this.form.interval
      this.show = true
      this.$nextTick(() => {
        this.$refs.instance.scrollIntoView()
      })
    },
    // 初始化数据
    async init() {
      this.initTime()
      this.paramStr = ''
      for (let key in this.form) {
        if (key !== 'interval' && this.form[key]) {
          this.paramStr += `${key}=~'${this.form[key]}',`
        }
      }
      this.paramStr = this.paramStr.substr(0, this.paramStr.length - 1)
      this.flag = true
      this.getBase()
    },
    // 初始化时间
    initTime() {
      let currentTime = Math.floor(new Date().getTime() / 1000)
      this.times = {
        start: currentTime - 12 * 60 * 60,
        end: currentTime,
        step: 1800
      }
    },
    // 获取基本数据
    async getBase() {
      try {
        const res = await Promise.all([
        // 获取基本数据
          this.$api.server.queryData({ query: `node_uname_info{${this.paramStr}}` }),
          // 运行时间
          this.$api.server.queryData({ query: `sum(time() - node_boot_time_seconds{${this.paramStr}})by(instance)` }),
          // 总内存
          this.$api.server.queryData({ query: `node_memory_MemTotal_bytes{${this.paramStr}} - 0` }),
          // 总核数
          this.$api.server.queryData({ query: `count(node_cpu_seconds_total{${this.paramStr ? `${this.paramStr}, mode='system'` : `mode='system'`}}) by (instance)` })
        ])
        let data = res.map(item => JSON.parse(item.data).data.result)
        this.result = deepClone(data)
        this.list = data[0].map(item => ({
          ...item.metric,
          bootTime: this.findOne(data, item.metric.instance, 1),
          cache: this.findOne(data, item.metric.instance, 2),
          Die: this.findOne(data, item.metric.instance, 3),
          load5: 0
        }))
        this.getData()
      } catch (error) {
        this.$message.error(error)
      }
    },
    // 获取详细数据
    async getData() {
      try {
        const res = await Promise.all([
          // 5分钟负载
          this.$api.server.queryData({ query: `sum(node_load5{${this.paramStr}})by(instance)` }),
          // CPU使用率
          this.$api.server.queryData({ query: `(1 - avg(rate(node_cpu_seconds_total{${this.paramStr ? `${this.paramStr},mode=~'idle'` : `mode=~'idle'`}}[${this.form.interval}])) by (instance)) * 100` }),
          // 内存使用率
          this.$api.server.queryData({ query: `(1 - (node_memory_MemAvailable_bytes{${this.paramStr}} / (node_memory_MemTotal_bytes{${this.paramStr}})))* 100` }),
          // 分区使用率
          this.$api.server.queryData({ query: `max((node_filesystem_size_bytes{${this.paramStr}}-node_filesystem_free_bytes{${this.paramStr}}) *100/(node_filesystem_avail_bytes {${this.paramStr}}+(node_filesystem_size_bytes{${this.paramStr}}-node_filesystem_free_bytes{${this.paramStr}})))by(instance)` }),
          // 最大读取
          this.$api.server.queryData({ query: `max(rate(node_disk_read_bytes_total{${this.paramStr}}[${this.form.interval}])) by (instance)` }),
          // 最大写入
          this.$api.server.queryData({ query: `max(rate(node_disk_written_bytes_total{${this.paramStr}}[${this.form.interval}])) by (instance)` }),
          // 连接数
          this.$api.server.queryData({ query: `node_netstat_Tcp_CurrEstab{${this.paramStr}} - 0` }),
          // TIME_WAIT
          this.$api.server.queryData({ query: `node_sockstat_TCP_tw{${this.paramStr}} - 0` }),
          // 下载宽带
          this.$api.server.queryData({ query: `max(rate(node_network_receive_bytes_total{${this.paramStr}}[${this.form.interval}])*8) by (instance)` }),
          // 上传带宽
          this.$api.server.queryData({ query: `max(rate(node_network_transmit_bytes_total{${this.paramStr}}[${this.form.interval}])*8) by (instance)` }),
          // 总磁盘量
          this.$api.server.queryData({ query: `max(node_filesystem_size_bytes{${this.paramStr}}) by(instance)` })
        ])
        let data = res.map(item => JSON.parse(item.data).data.result)
        this.result = deepClone(data)
        this.list = this.list.map(item => ({
          ...item,
          load5: this.findOne(data, item.instance, 0), // 5分钟负载
          cpuUsed: this.findOne(data, item.instance, 1), // CPU使用率
          memoryUsed: this.findOne(data, item.instance, 2), // 内存使用率
          zone: this.findOne(data, item.instance, 3), // 分区使用率
          maxRead: this.findOne(data, item.instance, 4), // 最大读取
          maxWritten: this.findOne(data, item.instance, 5), // 最大写入
          tcpCurrEstab: this.findOne(data, item.instance, 6), // 连接数
          TCPTw: this.findOne(data, item.instance, 7), // TIME_WAIT
          receive: this.findOne(data, item.instance, 8), // 下载宽带
          transmit: this.findOne(data, item.instance, 9), // 上传带宽
          filesystemSize: this.findOne(data, item.instance, 10) // 总磁盘量
        }))
      } catch (error) {
        this.$message.error(error)
      }
    },
    /**
   * 通过instance查找对应的数据
   * @param {Array} arr Promise.all返回的数据数组
   * @param {String} instance 当前节点唯一标识
   * @param {Number} i ar数组对应下标，与Promise.all获取的数据顺序相关联
   */
    findOne(arr, instance, i) {
      let data = ''
      if (arr[i].length > 0) {
        try {
          // 方法一： some查找
          arr[i].some((item, index) => {
            if (item.metric.instance === instance) {
              if (index < 0) { // 没有匹配到，执行全数组匹配
                data = this.result[i].find(it => it.metric.instance === instance)[0].value[1]
              } else { // 匹配到，当前项溢出数组，减少下次遍历次数
                data = arr[i][index].value[1]
                arr[i].splice(index, 1)
              }
              return true
            }
          })
          // 方法二： findIndex 查找
          // let index = arr[i].findIndex(item => item.metric.instance === instance)
          // if (index < 0) { // 没有匹配到，执行全数组匹配
          //   data = this.result[i].find(it => it.metric.instance === instance)[0].value[1]
          // } else { // 匹配到，当前项溢出数组，减少下次遍历次数
          //   data = arr[i][index].value[1]
          //   arr[i].splice(index, 1)
          // }
          return data
        } catch (error) {
          return 0
        }
      } else { // 匹配数组为空，基本数据中还有未匹配的数据项，执行全数组查找
        data = 0
        this.result[i].some((item, index) => {
          if (item.metric.instance === instance) {
            data = item.value[1]
            return true
          }
        })
        return data
        // return this.result[i].find(it => it.metric.instance === instance) ? this.result[i].find(it => it.metric.instance === instance)[0].value[1] : 0
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>

<style lang="scss" scoped>
  .label {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
  }
</style>
