<template>
  <el-row :gutter="12">
      <el-col :xs="24" :sm="12" :md="8">
         <avg-echarts :data="cupTotal" title="整体总负载与整体平均CPU使用率"></avg-echarts>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8">
         <avg-echarts :data="memTotal" title="整体总内存与整体平均内存使用率"></avg-echarts>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8">
         <avg-echarts :data="filesystemTotal" title="整体总磁盘与整体平均磁盘使用率"></avg-echarts>
      </el-col>
    </el-row>
</template>

<script>
import AvgEcharts from './AvgEcharts'
export default {
  components: {
    AvgEcharts
  },
  props: {
    form: {
      type: Object,
      default: () => {}
    },
    paramStr: {
      type: String,
      default: ''
    },
    times: {
      type: Object,
      default: () => {}
    },
    random: {
      type: [Number, String],
      default: '0'
    }
  },
  data() {
    return {
      cupTotal: [
        {
          label: 'x轴',
          data: []
        },
        {
          label: '总核数',
          data: []
        },
        {
          label: '总5分钟负载',
          data: []
        },
        {
          label: '平均使用率',
          data: []
        }
      ],
      memTotal: [
        {
          label: 'x轴',
          data: []
        },
        {
          label: '总内存/GiB',
          data: []
        },
        {
          label: '总已用/GiB',
          data: []
        },
        {
          label: '总平均使用率',
          data: []
        }
      ],
      filesystemTotal: [
        {
          label: 'x轴',
          data: []
        },
        {
          label: '总磁盘量/GiB',
          data: []
        },
        {
          label: '总使用量/GiB',
          data: []
        },
        {
          label: '总平均使用率',
          data: []
        }
      ]
    }
  },
  created() {
    this.getSecondsTotal()
    this.getMemTotal()
    this.getFilesystem()
  },
  watch: {
    form: {
      handler: function(val) {
        this.getSecondsTotal()
        this.getMemTotal()
        this.getFilesystem()
      },
      deep: true
    },
    random(val) {
      this.getSecondsTotal()
      this.getMemTotal()
      this.getFilesystem()
    }
  },
  methods: {
    // 总核数
    async getSecondsTotal() {
      const res = await Promise.all([
        // 总核数
        this.$api.server.queryDataTotal({
          query: `count(node_cpu_seconds_total{${this.paramStr ? `${this.paramStr},mode='system'` : `mode='system'`}})`,
          ...this.times
        }),
        // 总5分钟负载
        this.$api.server.queryDataTotal({
          query: `sum(node_load5{${this.paramStr}})`,
          ...this.times
        }),
        // 总平均使用率
        this.$api.server.queryDataTotal({
          query: `avg(1 - avg(rate(node_cpu_seconds_total{${this.paramStr ? `${this.paramStr},mode='idle'` : `mode='idle'`}}[${this.form.interval}])) by (instance)) * 100`,
          ...this.times
        })

      ])
      this.toEmpty(this.cupTotal)
      // let res1 = res.map(item => JSON.parse(item.data))
      // console.log(res1)
      res.forEach((item, index) => {
        try {
          Object.keys(JSON.parse(item.data).data.result[0].values).forEach(it => {
            if (index === 0) {
              this.cupTotal[0].data.push(JSON.parse(item.data).data.result[0].values[it][0].toString())
            }
            this.cupTotal[index + 1].data.push(Number(JSON.parse(item.data).data.result[0].values[it][1]).toFixed(1))
          })
        } catch (error) {
          console.log(error)
        }
      })
    },
    // 总内存
    async getMemTotal() {
      const res = await Promise.all([
        // 总内存
        this.$api.server.queryDataTotal({
          query: `sum(node_memory_MemTotal_bytes{${this.paramStr}})`,
          ...this.times
        }),
        // 总已用
        this.$api.server.queryDataTotal({
          query: `sum(node_memory_MemTotal_bytes{${this.paramStr}} - node_memory_MemAvailable_bytes{${this.paramStr}})`,
          ...this.times
        }),
        // 总平均使用率
        this.$api.server.queryDataTotal({
          query: `(sum(node_memory_MemTotal_bytes{${this.paramStr}} - node_memory_MemAvailable_bytes{${this.paramStr}}) / sum(node_memory_MemTotal_bytes{${this.paramStr}}))*100`,
          ...this.times
        })
      ])
      this.toEmpty(this.memTotal)
      res.forEach((item, index) => {
        try {
          Object.keys(JSON.parse(item.data).data.result[0].values).forEach(it => {
            if (index === 0) {
              this.memTotal[0].data.push(JSON.parse(item.data).data.result[0].values[it][0].toString())
            }
            if (index !== 2) {
              this.memTotal[index + 1].data.push((Number(JSON.parse(item.data).data.result[0].values[it][1]) / 1024 / 1024 / 1024).toFixed(2))
            } else {
              this.memTotal[index + 1].data.push(Number(JSON.parse(item.data).data.result[0].values[it][1]).toFixed(2))
            }
          })
        } catch (error) {
          console.log(error)
        }
      })
    },
    // 总磁盘
    async getFilesystem() {
      let params = this.paramStr ? `${this.paramStr}, fstype=~'xfs|ext.*'` : `fstype=~'xfs|ext.*'`
      const res = await Promise.all([
        // 总内存
        this.$api.server.queryDataTotal({
          query: `sum(avg(node_filesystem_size_bytes{${params}})by(device,instance))`,
          ...this.times
        }),
        // 总已用
        this.$api.server.queryDataTotal({
          query: `sum(avg(node_filesystem_size_bytes{${params}})by(device,instance)) - sum(avg(node_filesystem_free_bytes{${params}})by(device,instance))`,
          ...this.times
        }),
        // 总平均使用率
        this.$api.server.queryDataTotal({
          query: `(sum(avg(node_filesystem_size_bytes{${params}})by(device,instance)) - sum(avg(node_filesystem_free_bytes{${params}})by(device,instance))) *100/(sum(avg(node_filesystem_avail_bytes{${params}})by(device,instance))+(sum(avg(node_filesystem_size_bytes{${params}})by(device,instance)) - sum(avg(node_filesystem_free_bytes{${params}})by(device,instance))))`,
          ...this.times
        })
      ])
      this.toEmpty(this.filesystemTotal)
      res.forEach((item, index) => {
        try {
          Object.keys(JSON.parse(item.data).data.result[0].values).forEach(it => {
            if (index === 0) { // 总内存
              this.filesystemTotal[0].data.push(JSON.parse(item.data).data.result[0].values[it][0].toString())
            }
            if (index !== 2) { // 总已用
              this.filesystemTotal[index + 1].data.push((Number(JSON.parse(item.data).data.result[0].values[it][1]) / 1024 / 1024 / 1024).toFixed(2))
            } else { // 总平均使用率
              this.filesystemTotal[index + 1].data.push(Number(JSON.parse(item.data).data.result[0].values[it][1]).toFixed(2))
            }
          })
        } catch (error) {

        }
      })
    },
    toEmpty(arr) {
      arr.forEach(item => {
        item.data = []
      })
    }
  }
}
</script>

<style>

</style>
