<template>
  <div class="eachrt-com">
    <el-card>
      <div class="echart">
        <echart width="100%" height="300px" :options="option"></echart>
      </div>
    </el-card>
  </div>
</template>

<script>
import Echart from '@/components/Echart/index.vue'
import timeFormat from '@/utils/timeFormat'
export default {
  components: { Echart },
  props: {
    title: {
      type: String,
      defalut: ''
    },
    data: {
      type: Array,
      default: () => []
    },
    xTime: {
      type: Object,
      default: () => {}
    },
    titles: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      xData: [],
      option: {
        title: {
          show: true,
          text: '整体总负载',
          x: 'center'
        },
        tooltip: {
          trigger: 'axis',
          formatter: function (params, ticket, callback) {
            let str = timeFormat(params[0].axisValue * 1000, 'YYYY-MM-DD HH:mm:ss')

            params.forEach((item, index) => {
              if (index !== 2) {
                str += `<div style="display: flex; justify-content: space-between;"><span>${item.seriesName}：</span><span>${item.data}</span></div>`
              } else {
                str += `<div style="display: flex; justify-content: space-between;"><span>${item.seriesName}：</span><span>${item.data}%</span></div>`
              }
            })
            return str
          }
        },
        legend: {
          data: [],
          y: 'bottom',
          x: 'left'
        },
        xAxis: [
          {
            type: 'category',
            // 设置网格线颜色
            splitLine: {
              show: true,
              lineStyle: {
                color: ['#eee'],
                width: 1,
                type: 'solid'
              }
            },
            data: [],
            // x轴的字
            axisLabel: {
              show: true,
              showMinLabel: true,
              showMaxLabel: true,
              formatter: function (value, index) {
                return timeFormat(value * 1000, 'HH:mm')
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '',
            min: 0
          },
          {
            type: 'value',
            name: '',
            min: 0,
            splitLine: {
              show: false
            },
            axisLabel: {
              show: true,
              interval: 'auto',
              formatter: '{value} %'
            }
          }
        ],
        series: [
          {
            name: '',
            type: 'line',
            symbol: 'none',
            data: [],
            smooth: true
          },
          {
            name: '',
            type: 'line',
            symbol: 'none',
            data: [],
            smooth: true
          },
          {
            name: '',
            type: 'scatter',
            yAxisIndex: 1,
            symbolSize: 6,
            data: []
          }
        ]
      }
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.option.title.text = this.title
      let legendData = []
      for (let i = 1; i < this.data.length; i++) {
        legendData.push(this.data[i].label)
        this.option.series[i - 1].name = this.data[i].label
      }
      this.option.legend.data = legendData
      this.option.yAxis[0].name = this.data[1].label
      this.option.yAxis[1].name = this.data[2].label
    }
  },
  watch: {
    data: {
      handler: function(val) {
        // console.log(val)
        if (!val[0] && val[0].data.length <= 0) return
        this.option.xAxis[0].data = val[0].data
        for (let i = 1; i < val.length; i++) {
          this.option.series[i - 1].data = val[i].data
        }
      },
      deep: true,
      immediate: true
    }
  }
}

</script>

<style lang="scss" scoped>
.eachrt-com {
  margin-bottom: 12px;
  .echart {
    // background: $--color-white;
    // border-radius: 4px;
    padding: 10px;
    width: 100%;
    height: 300px;
  }
}
</style>
