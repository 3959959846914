var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"margin-bottom":"12px"}},[_c('table-list',{attrs:{"columns":_vm.columns,"headData":_vm.headData,"data":_vm.list,"fit":"","stripe":"","tableOption":{size: 'mini'},"cell-class-name":_vm.addClass},on:{"cell-click":_vm.cellClick},scopedSlots:_vm._u([{key:"instance",fn:function(ref){
var row = ref.row;
return [_c('a',[_vm._v(_vm._s(row.instance))])]}},{key:"bootTime",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("runTime")(row.bootTime))+" ")]}},{key:"cache",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.bit(row.cache, 3))+"GiB ")]}},{key:"cpuUsed",fn:function(ref){
var row = ref.row;
return [_c('ym-dadge',{attrs:{"type":_vm.statusType(row.cpuUsed),"animation":Boolean(_vm.statusType(row.cpuUsed))}}),_vm._v(" "+_vm._s(_vm._f("percentage")(row.cpuUsed))+" ")]}},{key:"memoryUsed",fn:function(ref){
var row = ref.row;
return [_c('ym-dadge',{attrs:{"type":_vm.statusType(row.memoryUsed),"animation":Boolean(_vm.statusType(row.memoryUsed))}}),_vm._v(" "+_vm._s(_vm._f("percentage")(row.memoryUsed))+" ")]}},{key:"zone",fn:function(ref){
var row = ref.row;
return [_c('ym-dadge',{attrs:{"type":_vm.statusType(row.zone),"animation":Boolean(_vm.statusType(row.zone))}}),_vm._v(" "+_vm._s(_vm._f("percentage")(row.zone))+" ")]}},{key:"maxRead",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.bit(row.maxRead, 1))+" kB/s ")]}},{key:"maxWritten",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.bit(row.maxWritten, 1))+" kB/s ")]}},{key:"receive",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.bit(row.receive, 1))+" KB/s ")]}},{key:"transmit",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.bit(row.transmit, 1))+" KB/s ")]}},{key:"filesystemSize",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.bit(row.filesystemSize, 3))+"GiB ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }