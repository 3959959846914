<template>
  <div style="margin-bottom: 12px">
    <table-list :columns="columns"
                :headData="headData"
                :data="list"
                fit
                stripe
                :tableOption="{size: 'mini'}"
                :cell-class-name="addClass"
                @cell-click="cellClick">
      <template #instance="{row}">
        <a>{{ row.instance }}</a>
      </template>
      <template #bootTime="{row}">
        {{ row.bootTime | runTime }}
      </template>
      <template #cache="{row}">
        {{ bit(row.cache, 3) }}GiB
      </template>
      <template #cpuUsed="{row}">
        <ym-dadge :type="statusType(row.cpuUsed)"
                  :animation="Boolean(statusType(row.cpuUsed))"></ym-dadge>
        {{ row.cpuUsed | percentage }}
      </template>
      <template #memoryUsed="{row}">
        <ym-dadge :type="statusType(row.memoryUsed)"
                  :animation="Boolean(statusType(row.memoryUsed))"></ym-dadge>
        {{ row.memoryUsed | percentage }}
      </template>
      <template #zone="{row}">
        <ym-dadge :type="statusType(row.zone)"
                  :animation="Boolean(statusType(row.zone))"></ym-dadge>
        {{ row.zone | percentage }}
      </template>
      <template #maxRead="{row}">
        {{ bit(row.maxRead, 1)}} kB/s
      </template>
      <template #maxWritten="{row}">
        {{ bit(row.maxWritten, 1)}} kB/s
      </template>
      <template #receive="{row}">
        {{ bit(row.receive, 1)}} KB/s
      </template>
      <template #transmit="{row}">
        {{ bit(row.transmit, 1)}} KB/s
      </template>
      <template #filesystemSize="{row}">
        {{ bit(row.filesystemSize, 3) }}GiB
      </template>
    </table-list>
  </div>
</template>

<script>
import TableList from '@/components/TableList'
import YmDadge from '@/components/Dadge'
import { format } from 'mathjs'
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  components: {
    TableList,
    YmDadge
  },
  data() {
    return {
      headData: {
        title: '服务器资源总览表'
      },
      columns: [
        {
          label: '应用',
          prop: 'app',
          width: 130,
          show: true
        },
        {
          label: 'ip(链接到明细)',
          prop: 'instance',
          width: 180,
          show: true,
          enableSlot: true
        },
        {
          label: '主机',
          prop: 'nodename',
          width: 200,
          show: true
        },
        {
          label: '运行时间',
          prop: 'bootTime',
          show: true,
          width: 100,
          align: 'center',
          enableSlot: true
        },
        {
          label: '内存',
          prop: 'cache',
          align: 'center',
          show: true,
          width: 120,
          enableSlot: true
        },
        {
          label: 'CPU核',
          prop: 'Die',
          align: 'center',
          width: 100,
          show: true
        },
        {
          label: '5m负载',
          width: 120,
          prop: 'load5',
          align: 'center',
          sortable: true,
          show: true
        },
        {
          label: 'CPU使用率',
          prop: 'cpuUsed',
          align: 'center',
          width: 140,
          show: true,
          sortable: true,
          enableSlot: true
        },
        {
          label: '内存使用率',
          width: 140,
          prop: 'memoryUsed',
          align: 'center',
          sortable: true,
          show: true,
          enableSlot: true
        },
        {
          label: '分区使用率',
          prop: 'zone',
          align: 'center',
          sortable: true,
          width: 140,
          show: true,
          enableSlot: true
        },
        {
          label: '磁盘读取',
          prop: 'maxRead',
          width: 120,
          align: 'center',
          sortable: true,
          show: true,
          enableSlot: true
        },
        {
          label: '磁盘写入',
          prop: 'maxWritten',
          width: 120,
          align: 'center',
          show: true,
          sortable: true,
          enableSlot: true
        },
        {
          label: '连接数',
          align: 'center',
          prop: 'tcpCurrEstab',
          width: 120,
          sortable: true,
          show: true
        },
        {
          label: 'TCP_tw',
          prop: 'TCPTw',
          align: 'center',
          width: 100,
          show: true
        },
        {
          label: '下载宽带',
          prop: 'receive',
          enableSlot: true,
          align: 'center',
          width: 120,
          show: true
        },
        {
          label: '上传宽带',
          prop: 'transmit',
          enableSlot: true,
          align: 'center',
          width: 120,
          show: true
        },
        {
          label: '总磁盘量',
          prop: 'filesystemSize',
          enableSlot: true,
          align: 'center',
          width: 120,
          show: true
        }
      ]
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.columns = this.columns.map((item) => ({
        ...item,
        sortMethod: item.sortable ? (a, b) => this.sort(a, b, item.prop) : null
      }))
    },
    // 排序
    sort(a, b, prop) {
      return b[prop] - a[prop]
    },
    bit(num, n) {
      if (Number(num) > 0) {
        return (num / Math.pow(1024, n)).toFixed(2)
      } else {
        return 0
      }
    },
    statusType(num) {
      if (Number(num) > 70) {
        if (Number(num) > 90) {
          return 'danger'
        } else {
          return 'warning'
        }
      }
    },
    // 校验大于70%
    valiate(row, column, prop) {
      if (Number(row[prop]) > 70 && column.property === prop) {
        return true
      }
    },
    // ip添加类
    addClass({ row, column, rowIndex, columnIndex }) {
      if (column.property === 'instance') {
        return 'instance'
      }
    },
    // 点击ip事件
    cellClick(row, column, cell, event) {
      if (column.property === 'instance') {
        this.$emit('on-details', row.instance)
      }
    }
  },
  filters: {
    runTime(time) {
      return Math.floor(format(time / 24 / 60 / 60, 14)) + ' 天'
    },
    percentage(value) {
      return Number(value).toFixed(2) + '%'
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .ym-table .el-table--mini td:last-child {
    padding: 4px 0 4px 24px !important;
}
::v-deep {
  .el-table {
    thead {
      .cell {
        // font-weight: 600;
        font-size: 12px;
      }
    }
    .instance {
      cursor: pointer;
      .cell {
        &:hover {
          text-decoration: underline;
          a {
            color: $--color-primary;
          }
        }
      }
    }
    // .danger {
    // background-color: #C23531;
    .cell {
      // display: flex;
      // align-items: center;
      .ym-badge {
        margin-right: 6px;
      }
    }
    // }
  }
}
@keyframes warn {
  0% {
    transform: scale(0.5);
    opacity: 1;
  }
  30% {
    opacity: 1;
  }
  100% {
    transform: scale(1.4);
    opacity: 0;
  }
}
</style>
