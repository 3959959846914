<template>
  <div class="eachrt-com">
    <el-card>
      <div class="echart">
        <echart width="100%" height="300px" :options="option"></echart>
      </div>
    </el-card>
  </div>
</template>

<script>
import timeFormat from '@/utils/timeFormat'
import Echart from '@/components/Echart/index.vue'
import { getColor } from '../utils'
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  components: {
    Echart
  },
  data() {
    return {
      option: {
        title: {
          show: true,
          text: '内存',
          x: 'center'
        },
        tooltip: {
          trigger: 'axis',
          formatter: function (params, ticket, callback) {
            let str = timeFormat(params[0].axisValue * 1000, 'YYYY-MM-DD HH:mm:ss')
            params.forEach((item, index) => {
              if (index !== 2) {
                str += `<div style="display: flex; justify-content: space-between;"><span>${item.seriesName}：</span><span>${Number(item.data).toFixed(2)}</span></div>`
              } else {
                str += `<div style="display: flex; justify-content: space-between;"><span>${item.seriesName}：</span><span>${Number(item.data).toFixed(2)}%</span></div>`
              }
            })
            return str
          }
        },
        legend: {
          data: [],
          y: 'bottom',
          x: 'left'
        },
        xAxis: [
          {
            type: 'category',
            // 设置网格线颜色
            splitLine: {
              show: true,
              lineStyle: {
                color: ['#eee'],
                width: 1,
                type: 'solid'
              }
            },
            data: [],
            // x轴的字
            axisLabel: {
              show: true,
              showMinLabel: true,
              showMaxLabel: true,
              formatter: function (value, index) {
                return timeFormat(value * 1000, 'HH:mm')
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '内存/GiB',
            min: 0
          },
          {
            type: 'value',
            name: '内存使用率',
            min: 0,
            splitLine: {
              show: false
            },
            axisLabel: {
              show: true,
              interval: 'auto',
              formatter: '{value} %'
            }
          }
        ],
        series: [{
          name: '',
          data: [],
          type: 'line',
          symbol: 'none',
          smooth: true
        },
        {
          name: '',
          data: [],
          type: 'line',
          symbol: 'none',
          smooth: true,
          areaStyle: {
            color: getColor('rgba(145,204,117, 0.3)', 'rgba(145,204,117, 0)')
          }
        },
        {
          name: '',
          data: [],
          type: 'line',
          symbol: 'none',
          smooth: true,
          areaStyle: {
            color: getColor('rgba(250,200,88, 0.3)', 'rgba(250,200,88, 0)')
          }
        },
        {
          name: '',
          type: 'scatter',
          yAxisIndex: 1,
          symbolSize: 6,
          data: []
        }]
      }
    }
  },
  watch: {
    data: {
      handler: function(val) {
        if (!val[0] && val[0].data.length <= 0) return
        this.option.xAxis[0].data = val[0].data
        for (let i = 1; i < val.length; i++) {
          this.option.series[i - 1].data = val[i].data
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.option.title.text = this.title ? this.title : this.option.title.text
      let legendData = []
      for (let i = 1; i < this.data.length; i++) {
        legendData.push(this.data[i].name)
        this.option.series[i - 1].name = this.data[i].name
      }
      this.option.legend.data = legendData
      // this.option.yAxis[0].name = this.data[1].name
      // this.option.yAxis[1].name = this.data[2].name
    }
  }
}
</script>

<style lang="scss" scoped>
.eachrt-com {
  margin-bottom: 12px;
  .echart {
    padding: 10px;
    width: 100%;
    height: 300px;
  }
}
</style>
