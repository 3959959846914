<template>
  <span :class="['ym-badge', type, animation ? 'animation' : '']" :style="[{ width: width + 'px', height: height + 'px' }]"></span>
</template>

<script>
export default {
  props: {
    background: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'primary'
    },
    animation: {
      type: Boolean,
      default: true
    },
    width: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
  $--primary: var(--primary, $--color-primary);
  $--warning: var(--warning, #f15b51);
  $--danger: var(--danger, #b42d40);
  $--transparent: transparent;
  .ym-badge {
    position: relative;
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: $--primary;
    &.animation {
      &.danger {
        background: $--danger;
        &::after {
          background-color: $--danger;
          border: 1px solid $--danger;
        }
      }
      &.warning {
        background: $--warning;
        &::after {
          background-color: $--warning;
          border: 1px solid $--warning;
        }
      }
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: translate(-10%, -10%);
        border-radius: 50%;
        display: inline-block;
        background-color: $--primary;
        border: 1px solid $--primary;
        animation: warn 1.2s ease-in-out infinite;
      }
    }
     @keyframes warn {
      0% {
        transform:  translate(-10%, -10%) scale(0.5);
        opacity: 0.5;
      }
      30% {
        transform: translate(-10%, -10%);
        opacity: 0.8;
      }
      100% {
        transform: translate(-10%, -10%) scale(1.8);
        opacity: 0;
      }
    }
  }
</style>
